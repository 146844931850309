import React from 'react';
import {
  MobileDrawer,
  Card,
  IconButton,
  MobileDrawerPosition,
} from 'wix-ui-tpa/cssVars';
import Text, { TextType } from '../../../Text/Text';
import { ModalContent } from '../ModalContent/ModalContent';
import { ButtonProp, ModalFooter } from '../ModalFooter/ModalFooter';
import { classes, st } from './MobileModal.st.css';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { MobileModalDataHooks } from './dataHooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ServiceData } from '../../../../../utils/state/initialStateFactory';
import { FormSelectedSlot } from '../../../../../types/formSelectedSlots';

export interface MobileModalProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  dataHook: string;
  dateRegionalSettingsLocale: string;
  primaryButton: ButtonProp;
  secondaryButton: ButtonProp;
  isOpen: boolean;
  onClose: () => {};
  title: string;
}

export const MobileModal: React.FC<MobileModalProps> = ({
  formSelectedSlot,
  serviceData,
  dateRegionalSettingsLocale,
  primaryButton,
  secondaryButton,
  isOpen,
  onClose,
  title,
  dataHook,
}) => {
  const { isRTL } = useEnvironment();
  return (
    <MobileDrawer
      data-hook={dataHook}
      isOpen={isOpen}
      className={st(classes.root, { rtl: isRTL })}
      onRequestClose={onClose}
      position={MobileDrawerPosition.Top}
    >
      <IconButton
        data-hook={MobileModalDataHooks.CLOSE_BUTTON}
        icon={<Close />}
        className={classes.closeButton}
        onClick={onClose}
      />
      <div className={classes.contentWrapper}>
        <Card stacked>
          <Card.Container>
            <Text type={TextType.Title} className={classes.title}>
              {title}
            </Text>
            <ModalContent
              formSelectedSlot={formSelectedSlot}
              serviceData={serviceData}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
          </Card.Container>
          <Card.Container className={classes.footerWrapper}>
            <ModalFooter
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
            />
          </Card.Container>
        </Card>
      </div>
    </MobileDrawer>
  );
};
