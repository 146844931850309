import { ReservedPaymentOptionIds } from '../../types/types';
import { ServiceData } from '../state/initialStateFactory';

export const getMaxNumberOfParticipants = (
  serviceData: ServiceData,
  slotServiceKey: string,
) => {
  const { selectedPaymentOption } = serviceData.slotServices[slotServiceKey];
  return (
    (selectedPaymentOption?.id === ReservedPaymentOptionIds.SingleSession
      ? serviceData?.maxNumberOfParticipantsWithoutPP ||
        serviceData?.maxNumberOfParticipants
      : serviceData?.maxNumberOfParticipants) || 1
  );
};
