import React, { FC, Suspense, useEffect, useRef } from 'react';
import {
  useEnvironment,
  useErrorBoundary,
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { classes, st } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { UserSelection } from './UserSelection/UserSelection';
import {
  FormState,
  SlotService,
} from '../../../utils/state/initialStateFactory';
import BookingDetails from './BookingDetails/BookingDetails';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import {
  DynamicPriceErrorType,
  EmptyStateErrorType,
  GenericErrorType,
} from '../../../types/errors';
import { PaymentSelection } from './PaymentSelection/PaymentSelection';
import BackButton from './BackButton/BackButton';
import {
  isNotOffered,
  isOfferedAsPricingPlanOnlyWithoutPlansConnected,
} from '../../../utils/payment/payment';
import { WidgetDataHooks } from './dataHooks';
import Coupon from './Coupon/Coupon';
import BookButtonContainer from './BookButton/BookButton';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { PaymentOption, ReservedPaymentOptionIds } from '../../../types/types';
import { getErrorByType, hasErrorOfType } from '../../../utils/errors/errors';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../../../utils/mappers/form-submission.mapper';
import { FormStatus } from '../../../types/form-state';
import { FormComponentContextProvider } from '../Hooks/useFormComponentContext';
import PriceOptionDropdownContainer from './PriceOptionDropdownContainer/PriceOptionDropdownContainer';
import { DynamicPriceInfo } from '../../../types/dynamicPrice';
import { ExperimentsConsts } from '../../../consts/experiments';
import CartModal from './CartModal/CartModal';
import CollapseForm from './CollapseForm/CollapseForm';
import settingsParams from '../settingsParams';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { getMaxNumberOfParticipants } from '../../../utils/numberOfParticipant';

const Dialog = React.lazy(() => import('./Dialog/Dialog'));
const Header = React.lazy(() => import('./Header/Header'));
const Toast = React.lazy(() => import('./Toast/Toast'));
const CancellationPolicy = React.lazy(
  () => import('./CancellationPolicy/CancellationPolicy'),
);

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  serviceData,
  actions,
  benefitsWithPlanInfo,
  pricingPlanDetails,
  isPricingPlanInstalled,
  formSelectedSlot,
  businessInfo,
  isMemberAreaInstalled,
  isCart,
  couponInfo,
  memberDetails,
  errors,
  editorContext,
  status,
  overrideDefaultFieldsValues,
  dialog,
  formInputs,
  isBookingsOnEcom,
  cartModal,
  shouldShowCollapseForm,
  collapseFormValues,
}) => {
  useEffect(() => {
    if (status === FormStatus.INITIALIZING) {
      actions.initializeWidget();
    }
  }, []);
  const { experiments } = useExperiments();

  const { addError } = actions;

  const isHideCouponInFormPageEnabled = experiments.enabled(
    ExperimentsConsts.HideCouponInFormPage,
  );

  const isCancellationPolicyChangeAPIEnabled = experiments.enabled(
    ExperimentsConsts.CancellationPolicyChangeAPI,
  );

  const isHidePriceWhenBuyinAPlanInDynamicPriceDropdownEnabled =
    experiments.enabled(
      ExperimentsConsts.HidePriceWhenBuyinAPlanInDynamicPriceDropdown,
    );

  const isDisableFormValidationWhenPurchasingAPricingPlanEnabled =
    experiments.enabled(
      ExperimentsConsts.DisableFormValidationWhenPurchasingAPricingPlan,
    );

  const formRef = useRef<FormRef>();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { error } = useErrorBoundary();
  const settings = useSettings();
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const { numberOfParticipants } = { ...formInputs };
  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing ||
    hasErrorOfType({ errorType: EmptyStateErrorType, errors }) ||
    error;

  const isDynamicPriceDropdownValid = (
    dynamicPriceInfo: DynamicPriceInfo,
    selectedPaymentOption: PaymentOption,
    currentMaxNumberOfParticipants: number,
  ) => {
    const selectedNumberOfParticipants =
      dynamicPriceInfo?.selectedVariants?.reduce(
        (acc, nextVariant) => acc + (nextVariant.numberOfParticipants || 0),
        0,
      ) || 0;

    const isAtLeastOneParticipantSelected = selectedNumberOfParticipants > 0;
    const isEqualToOrLessThanMaxParticipantsAllowed =
      selectedNumberOfParticipants <= currentMaxNumberOfParticipants;

    const isDropdownValid =
      isAtLeastOneParticipantSelected &&
      isEqualToOrLessThanMaxParticipantsAllowed;

    if (!isDropdownValid) {
      if (!isAtLeastOneParticipantSelected) {
        addError(DynamicPriceErrorType.MISSING_REQUIRED_FIELD_DATA, {
          message: t('app.form-inputs.validation-errors.required-field'),
        });
      } else {
        addError(DynamicPriceErrorType.NOT_ENOUGH_SESSIONS_FOR_DYNAMIC_PRICE, {
          message: t(
            'app.booking-details.price-not-enough-sessions-error-message',
            {
              planName: selectedPaymentOption.label,
            },
          ),
        });
      }
    }
    return isAtLeastOneParticipantSelected;
  };

  const submitForm = () => {
    // TODO: Change this function to support multi services
    const singleSlotService = mapToArray<SlotService>(
      serviceData.slotServices,
    )[0];
    const { selectedPaymentOption } = singleSlotService;
    const isBuyAPricingPlanFlow =
      selectedPaymentOption.id === ReservedPaymentOptionIds.BuyAPricingPlan;

    if (
      isDisableFormValidationWhenPurchasingAPricingPlanEnabled &&
      isBuyAPricingPlanFlow
    ) {
      return {
        state: { valid: true, errors: {} },
        submission: {},
      };
    }
    const submissionResponse = formRef?.current?.submit();
    const isDynamicPricingCustom =
      !!singleSlotService.dynamicPriceInfo?.customOptions?.length;
    if (
      singleSlotService.dynamicPriceInfo &&
      isDynamicPricingCustom &&
      serviceData.isSingleService &&
      (!isHidePriceWhenBuyinAPlanInDynamicPriceDropdownEnabled ||
        !isDisableFormValidationWhenPurchasingAPricingPlanEnabled ||
        !isBuyAPricingPlanFlow)
    ) {
      submissionResponse!.state.valid =
        submissionResponse?.state.valid! &&
        isDynamicPriceDropdownValid(
          singleSlotService.dynamicPriceInfo,
          selectedPaymentOption,
          getMaxNumberOfParticipants(
            serviceData,
            getServiceSlotIdentifier(singleSlotService.nestedSlot),
          ),
        );
    }
    return submissionResponse;
  };

  if (shouldShowEmptyStatePage()) {
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const someServiceNotValid = mapToArray<SlotService>(
    serviceData.slotServices,
  ).some(
    ({ service }) =>
      isOfferedAsPricingPlanOnlyWithoutPlansConnected(service?.payment) ||
      isNotOffered(service?.payment),
  );

  if (someServiceNotValid) {
    return (
      <EmptyStatePage
        isProcessing={isProcessing}
        title="app.empty-state-page.no-pp.title"
        subtitle="app.empty-state-page.no-pp.subtitle"
      />
    );
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  const showLoader = status === FormStatus.PROCESSING_BOOK_REQUEST;

  const canBookMultipleParticipants = !!getFieldFromSchema(
    serviceData.formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );

  const formFieldLables =
    shouldShowCollapseForm && serviceData.form
      ? {
          firstName: serviceData.form?.firstName?.label!,
          lastName: serviceData.form?.lastName?.label,
          email: serviceData.form?.email?.label!,
          phone: serviceData.form?.phone?.label!,
        }
      : undefined;

  return (
    <EditorContextProvider value={editorContext} key="form-main-widget">
      <FormActionsProvider value={{ ...actions, submitForm }}>
        <FormComponentContextProvider
          value={{
            isBookingsOnEcom,
          }}
        >
          <Suspense fallback={<div></div>}>
            <div
              className={st(classes.root, {
                isMobile,
                isProcessing: showLoader,
              })}
              data-hook={WidgetDataHooks.MAIN_CONTAINER}
            >
              <div className={classes.wrapper}>
                {showLoader ? (
                  <div
                    className={st(classes.blanket)}
                    data-hook={WidgetDataHooks.BLANKET}
                  />
                ) : null}
                {toastError ? <Toast toastError={toastError} /> : null}
                <BackButton />
                <div className={classes.body}>
                  <div className={classes.formWrapper}>
                    <Header
                      {...serviceData.form?.header}
                      title={
                        shouldShowCollapseForm
                          ? t(
                              'app.booking-details.collapse-fields-your-details',
                            )
                          : settings.get(settingsParams.formTitle) ||
                            serviceData.form?.header?.title
                      }
                    />
                    {isMemberAreaInstalled && !shouldShowCollapseForm && (
                      <UserSelection memberDetails={memberDetails} />
                    )}
                    {shouldShowCollapseForm && collapseFormValues && (
                      <CollapseForm
                        firstName={collapseFormValues?.firstName!}
                        lastName={collapseFormValues?.lastName!}
                        email={collapseFormValues?.email!}
                        phone={collapseFormValues?.phone!}
                        formFieldLables={formFieldLables!}
                      />
                    )}
                    <FormInputs
                      serviceData={serviceData}
                      formSchema={serviceData.formSchema}
                      formRef={formRef}
                      memberDetails={memberDetails}
                      overrideDefaultFieldsValues={overrideDefaultFieldsValues}
                    />
                    <PaymentSelection
                      serviceData={serviceData}
                      memberDetails={memberDetails}
                      numberOfParticipants={numberOfParticipants}
                      benefitsWithPlanInfo={benefitsWithPlanInfo}
                      isPricingPlanInstalled={isPricingPlanInstalled}
                      pricingPlanDetails={pricingPlanDetails}
                      isBookingsOnEcom={isBookingsOnEcom}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    />
                    <PriceOptionDropdownContainer
                      serviceData={serviceData}
                      status={status}
                      errors={errors}
                    />
                  </div>
                  <div className={classes.sidebar}>
                    <div className={classes.floatingContainer}>
                      <BookingDetails
                        serviceData={serviceData}
                        formSelectedSlot={formSelectedSlot}
                        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      />
                      {((!isHideCouponInFormPageEnabled && !isCart) ||
                        !isBookingsOnEcom) && (
                        <Coupon
                          couponInfo={couponInfo}
                          serviceData={serviceData}
                          errors={errors}
                          status={status}
                        />
                      )}
                      <PaymentSummary
                        serviceData={serviceData}
                        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                        numberOfParticipants={numberOfParticipants}
                        showPricePerParticipant={canBookMultipleParticipants}
                        appliedCoupon={couponInfo.appliedCoupon}
                        status={status}
                      />
                      <BookButtonContainer
                        serviceData={serviceData}
                        isCart={isCart}
                        shouldShowCollapseForm={shouldShowCollapseForm}
                        errors={errors}
                        status={status}
                      />
                      <CancellationPolicy
                        policy={
                          isCancellationPolicyChangeAPIEnabled
                            ? serviceData.cancellationPolicy
                            : businessInfo?.cancellationPolicy
                        }
                      />
                    </div>
                  </div>
                </div>
                {dialog ? <Dialog {...dialog.props} /> : null}
                {cartModal?.status ? (
                  <CartModal
                    cartModalStatus={cartModal?.status}
                    serviceData={serviceData}
                    formSelectedSlot={formSelectedSlot}
                    dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    cartLineItems={cartModal.lineItems}
                  />
                ) : null}
              </div>
            </div>
          </Suspense>
        </FormComponentContextProvider>
      </FormActionsProvider>
    </EditorContextProvider>
  );
};

export default Widget;
