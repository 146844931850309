import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { BookingDetailsDataHook } from './dataHooks';
import { classes, st } from './BookingDetails.st.css';
import { TagName } from '../../../../utils/tagName.const';
import VideoConferenceBadge from '../VideoConferenceBadge/VideoConferenceBadge';
import Duration from '../Duration/Duration';
import StaffMembers from '../StaffMembers/StaffMembers';
import Location from '../Location/Location';
import DateAndTime from '../DateAndTime/DateAndTime';
import Text, { TextType } from '../../Text/Text';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { getContent } from '../../../../utils/content/content';
import { ServiceData } from '../../../../utils/state/initialStateFactory';
import { FormSelectedSlot } from '../../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../../utils';

export interface BookingDetailsProps {
  dateRegionalSettingsLocale: string;
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({
  serviceData,
  formSelectedSlot,
  dateRegionalSettingsLocale,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summarySectionTitle,
  });

  return (
    <div className={st(classes.root, { isMobile })}>
      {formSelectedSlot.nestedSlots.map((slot) => {
        const service =
          serviceData.slotServices[getServiceSlotIdentifier(slot)].service;
        return (
          <>
            <SectionTitle
              label={sectionTitle}
              tagName={TagName.SecondaryHeading}
            />
            <VideoConferenceBadge
              videoConferenceProviderId={service.videoConferenceProviderId}
            />
            <Text
              type={TextType.Primary}
              data-hook={BookingDetailsDataHook.SERVICE_NAME}
              tagName={TagName.Paragraph}
            >
              {service.name}
            </Text>
            <DateAndTime
              totalNumberOfSessions={service.totalNumberOfSessions}
              slot={slot}
              service={service}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
            <Location location={service.location} />
            <StaffMembers staffMembers={service.staffMembers} />
            <Duration
              slot={slot}
              totalNumberOfSessions={service.totalNumberOfSessions}
            />
          </>
        );
      })}
    </div>
  );
};

export default BookingDetails;
